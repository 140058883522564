<template>
    <div class="tag-management">
        <HeaderContent :list="list" label="Manage Tag" marginBottom="16"></HeaderContent>

        <div class="content">
            <div class="left-panel">
                <ul class="tag-list">
                    <li v-for="(tag, index) in filteredTags" v-if="tag.id < 1000" :key="tag.id">
                        <div class="tag-container" :class="{ 'is-selected': (selectedId === tag.id) }" @click="toggleSelection(tag)">
                            <div class="tag-text">
                    <span class="icon">
                        <img v-if="tag.id < 1000 && tag.showChildren" src="@/assets/parent-tag.png" alt="icon"/>
                        <img v-else-if="tag.id < 1000 && !tag.showChildren" src="@/assets/parent-tag-2.png"
                             alt="icon"/>
                        <img v-else src="@/assets/child-tag.png" alt="icon"/>
                    </span>

                                <span class="tag-name">
                        {{ tag.name }}
              </span>
                            </div>
                            <span v-if="tag.children && tag.children.length" class="toggle-btn">
                        <button>
                            <svg v-if="tag.showChildren" width="11" height="7" viewBox="0 0 8 5" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
<path d="M4.1604 0.0742188C3.96899 0.0742188 3.80884 0.144531 3.64868 0.304688L0.758057 3.26172C0.640869 3.38281 0.582275 3.52344 0.582275 3.69141C0.582275 4.03906 0.859619 4.32031 1.20337 4.32031C1.37524 4.32031 1.5354 4.25 1.66431 4.12109L4.16431 1.54297L6.6604 4.12109C6.78931 4.25 6.94946 4.32031 7.11743 4.32031C7.46118 4.32031 7.73853 4.03906 7.73853 3.69141C7.73853 3.51953 7.67993 3.37891 7.56274 3.26172L4.67212 0.304688C4.51978 0.148438 4.35571 0.0742188 4.1604 0.0742188Z"
      fill="#FF617E"/>
</svg>
                            <svg v-else width="11" height="7" viewBox="0 0 8 5" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
<path d="M4.1604 4.92578C3.96899 4.92578 3.80884 4.85547 3.64868 4.69531L0.758057 1.73828C0.640869 1.61719 0.582275 1.47656 0.582275 1.30859C0.582275 0.960938 0.859619 0.679688 1.20337 0.679688C1.37524 0.679688 1.5354 0.75 1.66431 0.878906L4.16431 3.45703L6.6604 0.878906C6.78931 0.75 6.94946 0.679688 7.11743 0.679688C7.46118 0.679688 7.73853 0.960938 7.73853 1.30859C7.73853 1.48047 7.67993 1.62109 7.56274 1.73828L4.67212 4.69531C4.51978 4.85156 4.35571 4.92578 4.1604 4.92578Z"
      fill="#B8B8B8"/>
</svg>

                        </button>
                    </span>
                        </div>
                        <div v-if="tag.showChildren && tag.children && tag.children.length" class="child-container">
                            <ul class="child-list">
                                <li v-for="(child, childIndex) in tag.children" :key="child.id">
                                    <div class="child-item" :class="{ 'is-selected': (selectedId === child.id) }" @click="toggleSelection(child)">
                                        <span class="dot"></span>
                                        <span class="icon">
                                <img v-if="child.id < 1000" src="@/assets/parent-tag.png" alt="icon"/>
                                <img v-else src="@/assets/child-tag.png" alt="icon"/>
                            </span>

                                        <span class="tag-name">
                                {{ child.name }}
                            </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="right-panel">
                <div class="actions">
<!--                    <button @click="addTag">新增</button>-->
<!--                    <button @click="editTag">修改</button>-->
<!--                    <button @click="deleteTag">删除</button>-->
                    <button disabled style="background-color: grey; color: white; cursor: not-allowed;">Add</button>
                    <button disabled style="background-color: grey; color: white; cursor: not-allowed;">Modify</button>
                    <button disabled style="background-color: grey; color: white; cursor: not-allowed;">Detele</button>
                    <input type="text" v-model="searchQuery" placeholder="Search">
                </div>

                <div v-if="showTable" class="container">
                    <div class="header">
                        <h2>{{ h2Title }}</h2>
                    </div>
                    <div style="margin-left: 10px;background-color: #F3F7FF;padding: 3px 10px;border-radius: 4px;">
                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.09814 7.01855C2.19434 7.01855 0.611816 5.43604 0.611816 3.53223C0.611816 1.625 2.19092 0.0458984 4.09473 0.0458984C6.00195 0.0458984 7.58447 1.625 7.58447 3.53223C7.58447 5.43604 6.00537 7.01855 4.09814 7.01855ZM4.09814 4.16797C4.26904 4.16797 4.36816 4.07227 4.37158 3.8877L4.41943 2.02832C4.42285 1.84717 4.28271 1.71387 4.09473 1.71387C3.90332 1.71387 3.77002 1.84375 3.77344 2.0249L3.81787 3.8877C3.82129 4.06885 3.92041 4.16797 4.09814 4.16797ZM4.09814 5.31299C4.30322 5.31299 4.48096 5.15234 4.48096 4.94727C4.48096 4.74219 4.30664 4.57812 4.09814 4.57812C3.88965 4.57812 3.71533 4.74561 3.71533 4.94727C3.71533 5.14893 3.89307 5.31299 4.09814 5.31299Z"
                                  fill="#45BCFF"/>
                        </svg>
                        <span style="font-size: 12px">
                  输入新的标签键和标签值可创建全新标签，选择已有标签键可为该键新增标签值，输入标签值后回车确认.
              </span>
                    </div>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th>标签键</th>
                                <th>标签值</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in tableData" :key="index">
                                <td>
                                    <CustomSelect v-if="saveType === 1"
                                                  v-model="row.id"
                                                  holderText="默认一级标签"
                                                  :options="getFirstTags"
                                    />
                                    <CustomSelect v-if="saveType === 2"
                                                  v-model="row.id"
                                                  :holderText="getSelectedTags[0] ? getSelectedTags[0].name : '默认一级标签'"
                                                  :options="getSelectedTags"
                                    />
                                </td>
                                <td><input v-model="row.name"/></td>
                                <td style="display: flex;">
                                    <button @click="addRow(index)" class="circle-button">+</button>
                                    <button @click="removeRow(index)" class="table-button">删除</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="footer-actions">
                            <button @click="cancel">取消</button>
                            <button @click="save">保存</button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog-Delete
                    title="确认删除选中标签？"
                    :dialog="dialog"
                    @closeDialog="closeDialog"
                    @handleDelete="handleDelete"
            ></Dialog-Delete>
            <div v-if="alertSuccess" class="modal msuccess" @click="closeDialog">
                <div class="modal-content" @click.stop>
                    <p>{{ alertMassage }}</p>
                </div>
            </div>
            <div v-if="alertError" class="modal merror" @click="closeDialog">
                <div class="modal-content" @click.stop>
                    <p>{{ alertMassage }}</p>
                </div>
            </div>
            <div v-if="loading" class="loading-indicator">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderContent from "@/containers/HeaderContent";
    import {mapActions} from "vuex";
    import CustomSelect from './CustomSelect.vue';
    import DialogDelete from "../../../components/material/Dialog/DialogDelete";

    export default {
        components: {
            HeaderContent,
            CustomSelect,
            DialogDelete
        },
        mounted() {
            this.getResponseTags();
        },
        data() {
            return {
                list: [
                    {
                        text: "Konten",
                        disabled: true,
                    },
                    {
                        text: 'Manage Tag',
                        disabled: true
                    }
                ],
                dialogParent: false,
                tags: [],
                showTable: false,
                tableData: [{id: '', name: ''}],
                searchQuery: '',
                flatTags: [],
                saveType: 1,
                h2Title: '',
                selectedId: null,
                dialog: false,
                alertSuccess: false,
                alertError: false,
                alertMassage: '',
                loading: false,
            }
        },
        computed: {
            getFirstTags() {
                const tagMap = new Map();
                this.tags.forEach(tag => {
                    tagMap.set(tag.id, {...tag, children: [], showChildren: false});
                });
                const hierarchy = [];
                const defaultTag = {"id": "", "name": "默认一级标签"};
                hierarchy.push(defaultTag);
                tagMap.forEach(tag => {
                    if (tag.id < 1000) {
                        hierarchy.push(tag);
                    } else {
                        const prefix = tag.id.toString().slice(0, tag.id.toString().length - 3);
                        const parent = tagMap.get(Number(prefix));
                        if (parent) {
                            parent.children.push(tag);
                        }
                    }
                });
                return hierarchy;
            },
            getSelectedTags() {
                const selectedTags = [];

                this.tags.forEach(tag => {
                    if (tag.id.toString().startsWith(this.selectedId.toString())) {
                        selectedTags.push(tag);
                    }
                });
                return selectedTags;
            },
            filteredTags() {
                return this.flatTags.filter(item => {
                    const isMatch = item.name.toLowerCase().includes(this.searchQuery.toLowerCase());

                    const childrenMatches = item.children && item.children.some(child =>
                        child.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    );

                    if(this.searchQuery) {
                        item.showChildren = true;
                    }

                    return isMatch || childrenMatches;
                });
            }
        },
        methods: {
            ...mapActions({
                getTagList: "tags/getAllTags",
                createTagList: "tags/createTags",
                deleteTagById: "tags/deleteTags",
                editTagList: "tags/editTags",
            }),
            toggleDropdown(tagId) {
                const tag = this.tags.find(t => t.id === tagId);
                if (tag) {
                    tag.showDropdown = !tag.showDropdown;
                }
            },
            flattenTags(tags) {
                const result = [];
                const map = new Map();

                tags.forEach(tag => {
                    map.set(tag.id, {...tag, children: [], showChildren: false});
                });

                tags.forEach(tag => {
                    if (tag.id < 1000) {
                        result.push(map.get(tag.id));
                    } else {
                        const prefix = tag.id.toString().slice(0, tag.id.toString().length - 3);
                        const parent = map.get(Number(prefix));
                        if (parent) {
                            parent.children.push(map.get(tag.id));
                        }
                    }
                });

                const flatten = (items, level = 0) => {
                    return items.reduce((acc, item) => {
                        acc.push({...item, level});
                        if (item.children && item.children.length) {
                            acc = acc.concat(flatten(item.children, level + 1));
                        }
                        return acc;
                    }, []);
                };
                // console.info("flattenTags: " + JSON.stringify(flatten(result)));

                return flatten(result);
            },
            toggleSelection(tag) {
                if (this.selectedId === tag.id) {
                    this.selectedId = null;
                } else {
                    this.selectedId = tag.id;
                }
                this.$set(tag, 'showChildren', !tag.showChildren);
                if(this.saveType === 2) {
                    this.tableData = [{id: '', name: ''}];
                }
            },
            closeDialog() {
                this.dialog = false;
                this.alertSuccess = false;
                this.alertError = false;
                this.alertMassage = '';
            },
            addTag() {
                this.showTable = true;
                this.saveType = 1;
                this.h2Title = '新建标签';
            },
            editTag() {
                this.showTable = true;
                this.saveType = 2;
                this.h2Title = '修改标签';

                // Edit functionality here
            },
            deleteTag() {
                if(this.selectedId === null){
                    this.alertError = true;
                    this.alertMassage = '请选择一个标签进行删除';
                    setTimeout(() => {
                        this.alertError = false;
                        this.alertMassage = '';
                    }, 1000);
                    return;
                }
                this.saveType = 3;
                this.showTable = false;
                this.dialog = true;
            },
            addRow(index) {
                if(this.saveType === 1) {
                    this.tableData.splice(index + 1, 0, {id: '', name: ''});
                }else if(this.saveType === 2){
                    this.tableData.splice(index + 1, 0, {id: this.selectedId, name: ''});
                }
            },
            removeRow(index) {
                if (this.tableData.length > 1) {
                    this.tableData.splice(index, 1);
                }
            },
            cancel() {
                this.showTable = false;
                this.saveType = 1;
                this.tableData = [{id: '', name: ''}];
            },
            save() {
                console.log('Saving:', JSON.stringify(this.tableData));
                if (this.saveType === 1) {
                    this.tableData.forEach(item => {
                        item.id = '';
                    });
                    this.handleCreate();
                }
                if (this.saveType === 2) {
                    const tagMap = new Map();
                    this.tags.forEach(tag => {
                        tagMap.set(tag.id, {...tag, children: [], showChildren: false});
                    });
                    const hierarchy = [];
                    this.tableData.forEach(tag => {
                        const parent = tagMap.get(tag.id);
                        if (parent) {
                            parent.name = tag.name;
                            hierarchy.push(parent);
                        }
                    });
                    this.handleEdit(hierarchy);
                }
                if (this.saveType === 3) {
                    this.handleDelete();
                }
                this.showTable = false;
                this.saveType = 1;
                this.tableData = [{id: '', name: ''}];
            },
            async getResponseTags() {
                const response = await this.getTagList();
                if (response.status === 200) {
                    const responseData = response.data.data;
                    this.formatingResponse(responseData);
                    this.flatTags = this.flattenTags(this.tags);
                } else {
                    return response;
                }
            },
            formatingResponse(response) {
                this.totalPages = response.totalPages;
                const content = response.tagList;
                this.tags = content.map((res, index) => {
                    return {
                        parentId: res.parentId,
                        name: res.name,
                        description: res.description,
                        createAt: res.createAt,
                        id: res.id,
                        no: index + 1,
                    };
                });
            },
            async handleDelete() {
                this.loading = true;
                const id = this.selectedId;
                const response = await this.deleteTagById(id);
                if (response.status === 200) {
                    await this.getResponseTags();
                    this.dialog = false;
                    this.selectedId = "";
                    this.alertSuccess = true;
                    this.alertMassage = '删除成功';
                    setTimeout(() => {
                        this.alertSuccess = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                } else {
                    this.dialog = false;
                    this.selectedId = "";
                    this.alertError = true;
                    this.alertMassage = '删除失败';
                    setTimeout(() => {
                        this.alertError = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                }
            },
            async handleCreate() {
                this.loading = true;
                const payload = {
                    tagList: this.tableData,
                };
                const response = await this.createTagList(payload);
                if (response.status === 201) {
                    await this.getResponseTags();
                    this.alertSuccess = true;
                    this.selectedId = "";
                    this.alertMassage = '新增成功';
                    setTimeout(() => {
                        this.alertSuccess = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                } else {
                    this.selectedId = "";
                    this.alertError = true;
                    this.alertMassage = '新增失败';
                    setTimeout(() => {
                        this.alertError = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                }
            },
            async handleEdit(tagList) {
                this.loading = true;
                const payload = {
                    tagList: tagList,
                };
                const response = await this.editTagList(payload);
                if (response.status === 200) {
                    await this.getResponseTags();
                    this.alertSuccess = true;
                    this.selectedId = "";
                    this.alertMassage = '修改成功';
                    setTimeout(() => {
                        this.alertSuccess = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                } else {
                    this.selectedId = "";
                    this.alertError = true;
                    this.alertMassage = '修改失败';
                    setTimeout(() => {
                        this.alertError = false;
                        this.alertMassage = '';
                    }, 1000);
                    this.loading = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped src="./style.scss">

</style>